import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CreditCardValidators } from 'angular-cc-library';
import { FormService } from './form.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit {
  frmRegister: FormGroup;
  amount: number = 0;
  transactionId: string;
  transaction: any = {};
  disableSubmit = false;
  planes = [
    {
      name: 'Sin Plan de Pagos',
      planCode: '1',
      quotas: 1,
      _id: '0',
    },
  ];
  constructor(
    private route: ActivatedRoute,
    private _fb: FormBuilder,
    private formService: FormService
  ) {}

  ngOnInit() {
    this.transactionId = this.route.snapshot.params['transactionId'];
    this.formService.getTransaccion(this.transactionId).subscribe((res) => {
      this.transaction = res;
      this.amount = res.transaction.amount;
      this.planes = [
        {
          name: 'Sin Plan de Pagos',
          planCode: '1',
          quotas: 1,
          _id: '0',
        },
        ...res.installments,
      ];
      this.frmRegister.controls['plan'].setValue(this.planes[0]);
    });
    this.frmRegister = this._fb.group({
      number: [
        '',
        [Validators.required, CreditCardValidators.validateCCNumber],
      ],
      expirationDate: [
        '',
        [Validators.required, CreditCardValidators.validateExpDate],
      ],
      cvv: [
        '',
        [Validators.required, Validators.minLength(3), Validators.maxLength(4)],
      ],
      plan: this.planes[0],
    });
  }
  public goToNextField(controlName: string, nextField: HTMLInputElement) {
    if (this.frmRegister.get(controlName)?.valid) {
      nextField.focus();
    }
  }
  saveTransaction(transaction) {
    this.disableSubmit = true;
    const vencimiento = transaction.expirationDate
      .replace(/ /g, '')
      .replace('/', '');
    const expirationDate =
      vencimiento.substr(0, 2) + vencimiento.substr(vencimiento.length - 2);
    const parameters = {
      ...transaction,
      number: transaction.number.replace(/ /g, ''),
      expirationDate: expirationDate,
      transactionId: this.transactionId,
    };
    this.formService.saveTransaction(parameters).subscribe((res) => {
      const json = encodeURIComponent(JSON.stringify(res));
      this.disableSubmit = false;
      if (res.approved) {
        window.location.href =
          this.transaction.transaction.notifyUrlOk + '&response=' + json;
      } else {
        window.location.href =
          this.transaction.transaction.notifyUrlError + '&response=' + json;
      }
    });
  }
}
