<div class="container py-3">
  <div class="row">
    <div class="col-12 col-sm-8 col-md-6 mx-auto">
      <div id="pay-invoice" class="card">
        <div class="card-body">
          <div class="card-title">
            <h2 class="text-center">Formulario de Pago</h2>
          </div>
          <hr />
          <span *ngIf="transaction?.transaction?.description">
            <label>Artículo</label>
            <h6>{{ transaction?.transaction?.description }}</h6>
            <hr />
          </span>
          <form
            [formGroup]="frmRegister"
            (ngSubmit)="saveTransaction(frmRegister.value)"
            novalidate="novalidate"
          >
            <div class="form-group">
              <label>Monto a pagar</label>
              <h2>
                {{
                  amount
                    | currency: "$ "
                    | replace: ",":"_"
                    | replace: ".":","
                    | replace: "_":"."
                }}
              </h2>
            </div>

            <div class="form-group has-success">
              <label for="cc-number" class="control-label"
                >Número de Tarjeta Sucrédito</label
              >
              <input
                id="cc-number"
                (input)="goToNextField('number', expireInput)"
                name="cc-number"
                formControlName="number"
                type="tel"
                class="input-lg form-control cc-number"
                placeholder="•••• •••• •••• ••••"
                data-val="true"
                data-val-required="Por favor ingrese el número de tarjeta"
                data-val-cc-number="Ingrese un número válido de tarjeta"
                autocomplete="cc-number"
                ccNumber
              />
              <span
                class="help-block"
                data-valmsg-for="cc-number"
                data-valmsg-replace="true"
              ></span>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="cc-exp" class="control-label">Vencimiento</label>
                  <input
                    (input)="goToNextField('expirationDate', cvcInput)"
                    #expireInput
                    id="cc-exp"
                    name="cc-exp"
                    formControlName="expirationDate"
                    type="tel"
                    class="input-lg form-control cc-exp"
                    value=""
                    data-val="true"
                    data-val-required="Por favor ingrese la fecha de vencimiento"
                    data-val-cc-exp="Por favor ingrese un mes y año válidos"
                    placeholder="MMAA"
                    autocomplete="cc-exp"
                    placeholder="MM/AA"
                    ccExp
                  />
                  <span
                    class="help-block"
                    data-valmsg-for="cc-exp"
                    data-valmsg-replace="true"
                  ></span>
                </div>
              </div>
              <div class="col-6">
                <label for="x_card_code" class="control-label"
                  >Cód. seguridad</label
                >
                <div class="input-group">
                  <input
                    #cvcInput
                    id="x_card_code"
                    name="x_card_code"
                    formControlName="cvv"
                    type="password"
                    class="form-control cc-cvc"
                    value=""
                    data-val="true"
                    data-val-required="Por favor ingrese el código de seguridad"
                    data-val-cc-cvc="Por favor ingrese un código de seguridad válido"
                    autocomplete="off"
                    placeholder="•••"
                    required
                    ccCVC
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="cc-number" class="control-label"
                >Selecciona Plan de Pagos</label
              >
              <select id="country" formControlName="plan" class="form-control">
                <option *ngFor="let plan of planes" [ngValue]="plan">
                  {{ plan.name }}
                </option>
              </select>
            </div>

            <div>
              <button
                id="payment-button"
                type="submit"
                class="btn btn-lg btn-success btn-block"
                [disabled]="disableSubmit || !frmRegister.valid"
              >
                <i class="fa fa-lock fa-lg"></i>&nbsp;
                <span id="payment-button-amount"
                  >Pagar
                  {{
                    amount
                      | currency: "$ "
                      | replace: ",":"_"
                      | replace: ".":","
                      | replace: "_":"."
                  }}</span
                >
              </button>
              <br />
              <div class="leyenda">
                <b>CFT:</b> 0.0% (COSTO FINANCIERO TOTAL)<br /><b>TNA:</b> 0%
                (TASA NOMINAL ANUAL)
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
