import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  url = environment.apiUrl;
  constructor(private http: HttpClient) {}
  public saveTransaction(transactionData) {
    return this.http.post<any>(this.url + 'endTransaction', transactionData);
  }
  public getTransaccion(transactionId: string) {
    return this.http.post<any>(this.url + 'getTransaction', { transactionId });
    //.pipe(catchError(this.handleError('addHero', hero)));
  }
}
