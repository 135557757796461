import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormTestService } from './form-test.service';

@Component({
  selector: 'app-form-test',
  templateUrl: './form-test.component.html',
  styleUrls: ['./form-test.component.scss'],
})
export class FormTestComponent implements OnInit {
  frmRegister: FormGroup;
  amount: number = 0;
  result = null;
  planes = [
    { NOMBRE: 'Sin Plan de Pagos', PLAN: '1', CUOTAS: '1' },
    { NOMBRE: 'PLAN NORMAL 1 cuota', PLAN: '0', CUOTAS: '1' },
    { NOMBRE: 'PLAN NORMAL 2 cuotas', PLAN: '0', CUOTAS: '2' },
    { NOMBRE: 'PLAN NORMAL 3 cuotas', PLAN: '0', CUOTAS: '3' },
    { NOMBRE: 'PLAN NORMAL 4 cuotas', PLAN: '0', CUOTAS: '4' },
    { NOMBRE: 'PLAN NORMAL 5 cuotas', PLAN: '0', CUOTAS: '5' },
    { NOMBRE: 'PLAN NORMAL 6 cuotas', PLAN: '0', CUOTAS: '6' },
    { NOMBRE: 'PLAN NORMAL 7 cuotas', PLAN: '0', CUOTAS: '7' },
    { NOMBRE: 'PLAN NORMAL 8 cuotas', PLAN: '0', CUOTAS: '8' },
    { NOMBRE: 'PLAN NORMAL 9 cuotas', PLAN: '0', CUOTAS: '9' },
    { NOMBRE: 'PLAN NORMAL 10 cuotas', PLAN: '0', CUOTAS: '10' },
    { NOMBRE: 'PLAN NORMAL 11 cuotas', PLAN: '0', CUOTAS: '11' },
    { NOMBRE: 'PLAN NORMAL 12 cuotas', PLAN: '0', CUOTAS: '12' },
    { NOMBRE: 'PLAN NORMAL 13 cuotas', PLAN: '0', CUOTAS: '13' },
    { NOMBRE: 'PLAN NORMAL 14 cuotas', PLAN: '0', CUOTAS: '14' },
    { NOMBRE: 'PLAN NORMAL 15 cuotas', PLAN: '0', CUOTAS: '15' },
    { NOMBRE: 'PLAN NORMAL 16 cuotas', PLAN: '0', CUOTAS: '16' },
    { NOMBRE: 'PLAN NORMAL 17 cuotas', PLAN: '0', CUOTAS: '17' },
    { NOMBRE: 'PLAN NORMAL 18 cuotas', PLAN: '0', CUOTAS: '18' },
  ];
  constructor(private _fb: FormBuilder, private formService: FormTestService) {}

  ngOnInit() {
    this.frmRegister = this._fb.group({
      number: '',
      expirationDate: '',
      cvv: '',
      plan: this.planes[0],
      amount: 0.0,
      description: 'Producto Prueba',
    });
    this.onFormChanges();
  }
  onFormChanges() {
    this.frmRegister.valueChanges.subscribe((val) => {
      this.amount = val.amount;
    });
  }
  saveTransaction(transaction) {
    const param = { ...transaction, amount: transaction.amount + '' };
    // console .log(param);
    this.formService.saveTransaction(param).subscribe((res) => {
      this.result = res;
    });
  }
}
