import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FormTestService {
  url = environment.apiUrl;
  constructor(private http: HttpClient) {}
  public saveTransaction(transactionData) {
    return this.http.post<any>(this.url + 'testTransaction', transactionData);
  }
}
