<div class="container py-3">
  <div class="row">
    <div class="col-12 col-sm-8 col-md-6 mx-auto">
      <div id="pay-invoice" class="card">
        <div class="card-body">
          <div class="card-title">
            <h2 class="text-center">Formulario Ambiente Desarrollo</h2>
          </div>
          <hr />
          <form
            [formGroup]="frmRegister"
            (ngSubmit)="saveTransaction(frmRegister.value)"
            novalidate="novalidate"
          >
            <div class="form-group" *ngIf="result">
              <label>Resultado</label>
              <pre>{{ result | json }}</pre>
            </div>

            <div class="form-group has-success">
              <label for="cc-number" class="control-label">Número</label>
              <input
                id="cc-number"
                name="cc-number"
                formControlName="number"
                type="tel"
                class="form-control cc-number identified"
                value=""
                data-val="true"
                data-val-required="Por favor ingrese el número de tarjeta"
                data-val-cc-number="Ingrese un número válido de tarjeta"
                autocomplete="cc-number"
              />
              <span
                class="help-block"
                data-valmsg-for="cc-number"
                data-valmsg-replace="true"
              ></span>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="cc-exp" class="control-label">Vencimiento</label>
                  <input
                    id="cc-exp"
                    name="cc-exp"
                    formControlName="expirationDate"
                    type="tel"
                    class="form-control cc-exp"
                    value=""
                    data-val="true"
                    data-val-required="Por favor ingrese la fecha de vencimiento"
                    data-val-cc-exp="Por favor ingrese un mes y año válidos"
                    placeholder="MMAA"
                    autocomplete="cc-exp"
                  />
                  <span
                    class="help-block"
                    data-valmsg-for="cc-exp"
                    data-valmsg-replace="true"
                  ></span>
                </div>
              </div>
              <div class="col-6">
                <label for="x_card_code" class="control-label"
                  >Código de seguridad</label
                >
                <div class="input-group">
                  <input
                    id="x_card_code"
                    name="x_card_code"
                    formControlName="cvv"
                    type="password"
                    class="form-control cc-cvc"
                    value=""
                    data-val="true"
                    data-val-required="Por favor ingrese el código de seguridad"
                    data-val-cc-cvc="Por favor ingrese un código de seguridad válido"
                    autocomplete="off"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="cc-number" class="control-label"
                >Planes de Pago</label
              >
              <select id="country" formControlName="plan" class="form-control">
                <option *ngFor="let plan of planes" [ngValue]="plan">
                  {{ plan.NOMBRE }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="cc-number" class="control-label">Monto</label>
              <input
                formControlName="amount"
                type="number"
                class="form-control"
                value=""
                autocomplete="cc-number"
              />
            </div>
            <div class="form-group">
              <label for="cc-number" class="control-label">Descripción</label>
              <input
                formControlName="description"
                type="text"
                class="form-control"
                value=""
                autocomplete="cc-number"
              />
            </div>

            <div>
              <button
                id="payment-button"
                type="submit"
                class="btn btn-lg btn-success btn-block"
              >
                <i class="fa fa-lock fa-lg"></i>&nbsp;
                <span id="payment-button-amount"
                  >Pagar {{ amount | currency }}</span
                >
                <span id="payment-button-sending" style="display: none"
                  >Procesando…</span
                >
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
