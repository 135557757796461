import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { FormTestComponent } from './form-test/form-test.component';
import { FormComponent } from './form/form.component';

const routes: Routes = [
  {
    path: 'form/:transactionId',
    component: FormComponent,
  },
  // {
  //   path: 'test',
  //   component: FormTestComponent,
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
